<template>
  <v-container
    fluid
    tag="section"
    data-cy="pageGestionSondages"
  >
    <base-material-card
      icon="mdi-chart-box-outline"
      inline
      class="px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          {{ $t('sondages_page.sondages') }}
        </div>
      </template>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto"
        label="Search"
        hide-details
        single-line
        style="max-width: 250px;"
      />

      <v-divider class="mt-3" />

      <v-data-table
        data-cy="dtSondages"
        :headers="headers"
        :items="listeSondages"
          :footer-props="{
                  itemsPerPageText: $t('lignes_par_page'),
                  itemsPerPageAllText: $t('tout'),
                   pageText: `{0}-{1} ${$t('general.de')} {2}`
                }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            class="px-2 ml-1 primary"
            min-width="0"
            small
            @click="onEditer(item)"
          >
            <v-icon
              small
            >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn
            class="px-2 ml-1 warning"
            min-width="0"
            small
            @click="onEffacer(item)"
          >
            <v-icon
              small
            >
              mdi-trash-can-outline
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-btn
        data-cy="btnCreerSondage"
        class="primary"
        @click="onAjouter()"
      >
        {{ $t('sondages_page.creer_nouveau_sondage') }}
      </v-btn>
    </base-material-card>
  </v-container>
</template>

<script>
  import restApiService from '@/services/restApiService.js'
  import i18n from '@/i18n.js'

  export default {
    data: () => ({
      search: undefined,
      listeSondages: [],
      headers: [
        { text: i18n.t('nom_commun'), value: 'nom' },
        { text: i18n.t('actions'), value: 'actions' },
      ],
      nouveauSondage: {
        compteClient: {
          id: undefined,
        },
      },
    }),
    watch: {
      options: {
        handler () {
          this.getData()
        },
        deep: true,
      },
    },
    mounted () {
      this.getData()
    },
    methods: {
      getData () {
        restApiService
          .get('/api/sondages/compteClient/' + this.$store.state.compteClient.id)
          .then((result) => {
            this.listeSondages = result.data
          })
          .catch((erreur) => alert(erreur))
        // TODO: Faut régler cette histoire de compte clients
        this.nouveauSondage.compteClient.id = this.$store.state.compteClient.id
      },
      onEditer (sondage) {
        this.$router.push({ path: '/sondage/editionSondage/' + sondage.id })
      },
      async onAjouter () {
        const reponse = await this.$dialog.prompt({
          title: i18n.t('sondages_page.creer_nouveau_sondage'),
          text: i18n.t('sondages_page.saisir_nom_du_sondage'),
          rules: [
            v => !!v || i18n.t('champ_requis'),
          ],
          textField: {
            type: 'text',
            id: 'nouveauSondageNomInput',
          },
          actions: {
            false: i18n.t('annuler'),
            true: {
              color: 'red',
              text: i18n.t('confirmer'),
              handle: () => {
                return new Promise(resolve => {
                  this.nouveauSondage.nom = document.getElementById('nouveauSondageNomInput').value
                  if (this.nouveauSondage.nom) {
                    resolve(true)
                  } else { resolve(false) }
                })
              },
            },
          },
        }).catch(() => {
          console.log('Prompt dismissed')
        })

        if (reponse) {
          restApiService
            .post('/api/sondages', this.nouveauSondage)
            .then((result) => {
              history.pushState({}, null, '/#/sondage/editionSondage/' + result.data.id)
              this.$router.push({ path: `/sondage/editionSondage/${result.data.id}` + '?nouveau=true' })
            })
            .catch((erreur) => alert(erreur))
        }
      },
      onEffacer (sondage) {
        this.$dialog.confirm({
          text: this.$t('sondages_page.confirmer_suppression_sondage'),
          title: this.$t('attention'),
        }).then((r) => {
          if (r) {
            restApiService
              .delete('/api/sondages/' + sondage.id)
              .then((result) => {
                this.getData()
              })
              .catch((erreur) => alert(erreur))
          }
        })
      },

    },
  }
</script>
